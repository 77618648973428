import axios from 'axios';
import {baseUrl} from '@/js/util.js'

// 获取轮播图
export const F_Get_shufflingPictureList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_Get_shufflingPictureList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}

// 获取课程推荐
export const F_Get_phaseRecommendedList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_Get_phaseRecommendedList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}

// 获取课程统计
export const F_Get_bstatiSticalrowse=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Home/F_Get_bstatiSticalrowse`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获取课程统计
export const F_Get_subscribeCourseForStudentId=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_User/F_Get_subscribeCourseForStudentId`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}

// 获取学校下拉接口
export const get_select_schoolList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_schoolList`,
        params:datas,
    })
}
// 获取院系下拉接口
export const get_select_departmentList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_departmentList`,
        params:datas,
    })
}

// 获取专业下拉列表
export const get_select_majorList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_majorList`,
        params:datas
    })
}
// 获取角色下拉列表
export const get_select_role=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_roleList`,
        params:datas
    })
}
// 获老师课程数据列表
export const f_get_teacherCoursePageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_teacherCoursePageList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 修改课程状态
export const f_update_courseStatus=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_update_courseStatus`,
        data:datas,
        headers:{
            "Content-Type":"application/json"
        }
    })
}
// 获老师下的班级
export const f_get_teacherByClassNum=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_teacherByClassNum`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获课程题库
export const f_get_courseQuestionBankPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_courseQuestionBankPageList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获取实训题库分页列表
export const f_get_trainingQuestionBankPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_trainingQuestionBankPageList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}

// 获课程下拉数据
export const get_select_courseList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_courseList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获课程章下拉数据
export const get_select_courseChapterList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_courseChapterList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获课程节下拉数据
export const get_select_courseSectionList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_courseSectionList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 添加/修改题库试题
export const f_update_courseQuestion=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_update_courseQuestion`,
        data:datas,
    })
}
// 添加/修改题库实训试题
export const f_update_trainingQuestion=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_update_trainingQuestion`,
        data:datas,
    })
}

// 删除试题
export const f_delete_courseQuestion=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_delete_courseQuestion`,
        data:datas,
        headers:{
            "Content-Type":"application/json"
        }
    })
}
// 获取系统分页数据
export const f_get_systemExperiencePageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_systemExperiencePageList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 下载Excel导入课程试题模板
export const DownloadCourseQuestionExcel=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/DownloadCourseQuestionExcel`,
        params:datas,
        responseType: "blob",
        headers: {'isFrontEnt':true},
    })
}

// 下载Excel导入实训课程试题模板
export const DownloadTrainingQuestionExcel=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/DownloadTrainingQuestionExcel`,
        params:datas,
        responseType: "blob",
        headers: {'isFrontEnt':true},
    })
}

// 导入课程题库试题
export const f_ImportExcel_courseQuestion=(datas)=>{
    return axios({
        method:'post',
        data:datas,
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_ImportExcel_courseQuestion`,
        headers: {'isFrontEnt':true,'Content-Type': 'application/x-www-form-urlencoded'},
    })
}
// 导入实训题库试题
export const f_ImportExcel_trainingQuestion=(datas)=>{
    return axios({
        method:'post',
        data:datas,
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_ImportExcel_trainingQuestion`,
        headers: {'isFrontEnt':true,'Content-Type': 'application/x-www-form-urlencoded'},
    })
}

// 获取系统实训下拉数据
export const get_select_trainingSystem=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_trainingSystem`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}

// 获取实训系统模块
export const get_select_trainingSystemModule=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_trainingSystemModule`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}

// 获取符合条件的替换试题
export const get_select_questionList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/Dictionary/get_select_questionList`,
        params:datas
    })
}
//修改课程实训状态
export const f_update_digitalTrainingStatus=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_user/f_update_userSubject`,
        data:datas,
        headers:{
            "Content-Type":"application/json"
        }
    })
}


// 获取帮助中心
export const b_get_helpCenterPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/B_helpCenter/b_get_helpCenterPageList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 添加/修改系统实训
export const f_update_systemExperience=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_update_systemExperience`,
        data:datas,
    })
}
//修改系统实训状态
export const f_update_systemExperienceStatus=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_update_systemExperienceStatus`,
        data:datas,
        headers:{
            "Content-Type":"application/json"
        }
    })
}
// 添加/修改课程实训
export const f_update_digitalTraining=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_update_digitalTraining`,
        data:datas,
    })
}
//修改课程实训状态
export const f_update_digitalTrainingStatusss=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_update_digitalTrainingStatus`,
        data:datas,
        headers:{
            "Content-Type":"application/json"
        }
    })
}
// 添加/修改课程管理的节作业
export const f_update_courseExercises=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_update_courseExercises`,
        data:datas,
    })
}
// 获取磕碜给管理的节作业
export const f_get_courseExercises=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_courseExercises`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获取学生的历史作业
export const f_get_studentJobHistory=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_studentUser/f_get_studentJobHistory`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获取学生的实训成绩
export const f_get_studentTrainingHistory=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_studentUser/f_get_studentTrainingHistory`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
// 获取学生的历史考试
export const f_get_studentExaminationHistory=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_studentUser/f_get_studentExaminationHistory`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}